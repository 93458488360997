import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Response20x} from '@rapi/w3';
import {ActionPanelEnum, SessionPanel} from '@sip/common/models';

import {environment} from '../../../environments/environment';
import {IRepresentativeResponse, IRepresentativeVoteDto, IRepresentativeVoteResponse, UnitModel} from '../models/partipant.model';
import {SessionConfigService} from './session-config.service';
import {ClearDataEvent} from './events';

@Injectable()
export class RepresentativeService {

    public slideID$: Observable<number | null>;
    private slideIDSubject = new BehaviorSubject<number | null>(null);

    constructor(private http: HttpClient, private readonly sessionConfig: SessionConfigService) {
        this.slideID$ = this.slideIDSubject.asObservable();

        this.sessionConfig.broadCast$
            .pipe(
                filter((e): e is ClearDataEvent => e instanceof ClearDataEvent)
            )
            .subscribe(() => {
                this.slideIDSubject.next(null);
            });

        this.sessionConfig.broadCast$
            .pipe(
                filter((e): e is SessionPanel => e instanceof SessionPanel)
            )
            .subscribe((e: SessionPanel) => {
                switch (e.action) {
                    case ActionPanelEnum.questReset:
                        this.setSlideID(this.getSlideID());
                        break;
                }
            });
    }

    getSlideID(): number | null {
        return this.slideIDSubject.getValue();
    }

    setSlideID(id: number | null): void {
        this.slideIDSubject.next(id);
    }

    getUnities(): Observable<{ quantity: number, weight_total: number, unities: UnitModel[] }> {
        return this.http
            .get<Response20x>(`${environment.URL_API}/mod/participants/representative/unities`)
            .pipe(
                map((r) => r.data),
                map((data) => {
                    data.unities = data.unities.map(unit => this.presentUnit(unit));
                    return data;
                })
            );
    }

    getVotes(slideId: number | string): Observable<IRepresentativeResponse> {
        return this.http
            .get<Response20x>(`${environment.URL_API}/mod/participants/representative/votes/${slideId}`)
            .pipe(
                map((r) => r.data),
                map((data: IRepresentativeResponse) => {
                    data.unities = data.unities.map(sub => {
                        sub.unit = this.presentUnit(sub.unit);
                        return sub;
                    });
                    return data;
                })
            );
    }

    sendVotes(dto: IRepresentativeVoteDto): Observable<IRepresentativeVoteResponse> {
        return this.http
            .post<Response20x>(`${environment.URL_API}/mod/participants/representative/votes`, dto)
            .pipe(map((data: IRepresentativeVoteResponse) => {
                data.data.map(d => {
                    d.unit = this.presentUnit(d.unit);
                    return d;
                });

                return data;
            }));
    }

    private presentUnit(unit: UnitModel): UnitModel {
        unit.weight = isNaN(+unit.weight) ? 1 : +unit.weight;
        return unit;
    }

}
